.container {
    padding: 20px;
    text-align: center;
  }
  
  .textarea {
    width: 95%;
    height: 100px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .skeletonContainer {
    margin-top: 20px;
  }
  
  .responseContainer {
    margin-top: 20px;
    text-align: left;
  }
  
  .networkContainer {
    margin-bottom: 20px;
  }
  
  .networkTitle {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .messageList {
    list-style: none;
    padding: 0;
  }
  
  .messageItem {
    margin: 5px 0;
    color: #555;
  }
  
  .errorMessage {
    color: red;
    margin-top: 20px;
  }
  